import Vue from 'vue'
import VueRouter from 'vue-router'
// import VueMeta from 'vue-meta'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import dashboard from './routes/dashboard'
import pages from './routes/pages'

Vue.use(VueRouter)
// Vue.use(VueMeta)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...dashboard,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
    // let all_menu = JSON.parse(localStorage.getItem('all_menu'))
    // var count = all_menu.filter(menu => {
    //     return '/' + to.meta.permissionlink == menu.url
    // })

    // let count1 = all_menu.filter(menu => {
    //     return menu.submenu.length > 0
    // })

    // var deep = [];

    // if (count1.length > 0) {
    //     let countDeep = count1.filter(menu => {
    //         if (deep.length == 0) {
    //             deep = menu.submenu.filter(child => {
    //                 return child.url == '/' + to.meta.permissionlink
    //             })
    //         }
    //     })
    // }
    // let actual = ''
    // let actual1 = ''
    // count.forEach(element => {
    //     actual = element.url
    // });
    // deep.forEach(element => {
    //     actual1 = element.url
    // });
    // if (actual != '' || actual1 != '') {
    //     next()
    // } else {
    //     next('/forbidden')
    // }
  

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
const DEFAULT_TITLE = "WEB APP - SPPD";
router.afterEach((to, from) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
    Vue.nextTick(() => {
      document.title = to.meta.title || DEFAULT_TITLE
    });
  }
})

export default router
